import React from 'react'
import Helmet from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import AllPosts from '../AllPosts'
import PropTypes from 'prop-types'
import siteConfig from '../../../config/siteConfig'

const styles = theme => ({
    search: {
        textAlign: 'left',
        textTransform: 'lowercase',
        paddingTop: theme.spacing.unit*2,
        paddingLeft: theme.spacing.unit*4,
        paddingBottom: theme.spacing.unit,
        //opacity: 0.3
    },
    searchCat: {
        color: '#A20471',
        textTransform: 'uppercase',
        fontWeight: 500
    }
})

const Categories = ({ pageContext, data, classes, location }) => {
    const { category } = pageContext
    const { totalCount } = data.allMarkdownRemark
    const categoryHeader = `${category}(${totalCount})`

    return (
        <Layout>
            <Helmet 
                title={`Category: ${category}`}
                link={[
                    { rel: 'canonical', href: `${siteConfig.siteUrl}` }
                ]}
            />
            <h1 className={classes.search}>Category: <span className={classes.searchCat}>{categoryHeader}</span></h1>
            <AllPosts data={data} context={pageContext} />
        </Layout>
    )
}

Categories.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
}

export default withStyles(styles)(Categories)

export const pageQuery = graphql`
  query($category: String, $limit: Int!, $skip: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            path
            categories
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600,
                    duotone: {
                      highlight: "#A20471",
                      shadow: "#40C4FF",
                      opacity: 20
                    }, quality: 100, cropFocus: CENTER) {
                    base64
                    src
                    srcSet
                    sizes
                    aspectRatio
                    originalImg
                }
              }
            }
          }
        }
      }
    }
  }
`